import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GenericModalComponent } from 'src/app/components/generic-modal/generic-modal.component';
// import { GlobalHeaderComponent } from 'src/app/components/global-header/global-header.component';
// import { StepperComponent } from 'src/app/components/stepper/stepper.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
// import { QRCodeModule } from 'angularx-qrcode';
import { TooltipModule } from 'ng2-tooltip-directive';
// import { NgxBarcodeModule } from 'ngx-barcode';
import { SwiperModule } from 'swiper/angular';

import { BeneficiosCategoriasComponent } from 'src/app/components/beneficios-categorias/beneficios-categorias.component';
import {
  BeneficiosSegmentacionComponent,
} from 'src/app/components/beneficios-segmentacion/beneficios-segmentacion.component';
import { BeneficiosComponent } from 'src/app/components/beneficios/beneficios.component';
import { CategoriasComponent } from 'src/app/components/categorias/categorias.component';
import { ClientPrimeComponent } from 'src/app/components/client-prime/client-prime.component';
import { InscripcionHomeComponent } from 'src/app/components/inscripcion-home/inscripcion-home.component';
import {
  ModalAppPuntoscalificablesComponent,
} from 'src/app/components/modal-app-puntoscalificables/modal-app-puntoscalificables.component';
import {
  ModalAppTerminoscondicionesbeneficiosComponent,
} from 'src/app/components/modal-app-terminoscondicionesbeneficios/modal-app-terminoscondicionesbeneficios.component';
import {
  PreguntasFrecuentesCategoriasComponent,
} from 'src/app/components/preguntas-frecuentes-categorias/preguntas-frecuentes-categorias.component';
import { PromocionesHomeComponent } from 'src/app/components/promociones-home/promociones-home.component';
import { SegmentacionesComponent } from 'src/app/components/segmentaciones/segmentaciones.component';
import { VerPromocionesComponent } from 'src/app/components/ver-promociones/ver-promociones.component';
import { VitrinaSliderHomeComponent } from 'src/app/components/vitrina-slider-home/vitrina-slider-home.component';
import { VitrinaComponent } from 'src/app/components/vitrina/vitrina.component';

import { AfiliacionBannerComponent } from './afiliacion-banner/afiliacion-banner.component';
import { AfiliacionPreguntasComponent } from './afiliacion-preguntas/afiliacion-preguntas.component';
import { AfiliacionSintarjetaComponent } from './afiliacion-sintarjeta/afiliacion-sintarjeta.component';
import { AfiliacionTerminoslegalesComponent } from './afiliacion-terminoslegales/afiliacion-terminoslegales.component';
import { AfiliacionVitrinaComponent } from './afiliacion-vitrina/afiliacion-vitrina.component';
import { BeneficiosPromocionesComponent } from './beneficios-promociones/beneficios-promociones.component';
import { CanjesPromocionesComponent } from './canjes-promociones/canjes-promociones.component';
import { CanjesPromocionesLatamComponent } from './canjes-promociones-latam/canjes-promociones-latam.component';
import { ClientPrimeCanjePromoComponent } from './client-prime-canje-promo/client-prime-canje-promo.component';
import { ComoacumularPuntoscategoriaComponent } from './comoacumular-puntoscategoria/comoacumular-puntoscategoria.component';
import { ComoacumularRipleypuntosComponent } from './comoacumular-ripleypuntos/comoacumular-ripleypuntos.component';
import { ComocanjearRipleypuntosComponent } from './comocanjear-ripleypuntos/comocanjear-ripleypuntos.component';
import { ConsultarRipleypuntosComponent } from './consultar-ripleypuntos/consultar-ripleypuntos.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ModalAppTerminoslegalComponent } from './modal-app-terminoslegal/modal-app-terminoslegal.component';
import { NotificationsBellComponentModule } from './notifications-bell/notifications-bell.module';
import { PantallaCargaComponent } from './pantalla-carga/pantalla-carga.component';
import { DescubreMillaslatamComponent } from './redeem-latam/descubre-millaslatam/descubre-millaslatam.component';
import { PantallaNotfoundComponent } from './redeem-latam/pantalla-notfound/pantalla-notfound.component';
import { PantallaNotcanjeComponent } from './redeem-latam/pantalla-notcanje/pantalla-notcanje.component';
import { PantallaNotripleyComponent } from './redeem-latam/pantalla-notripley/pantalla-notripley.component';
import { PaqueteLatamComponent } from './redeem-latam/paquete-latam/paquete-latam.component';
import { ConfirmaPaqueteComponent } from './redeem-latam/confirma-paquete/confirma-paquete.component';
import { ConfirmaEstadoCanjeComponent } from './redeem-latam/confirma-estado-canje/confirma-estado-canje.component';
import { PaqueteExitosoComponent } from './redeem-latam/paquete-exitoso/paquete-exitoso.component';
import { DetalleCanjeComponent } from './redeem-latam/detalle-canje/detalle-canje.component';
import { MiscanjesLatamComponent } from './redeem-latam/miscanjes-latam/miscanjes-latam.component';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import {
  PromocionesTerminosCondicionesComponent,
} from './promociones-terminos-condiciones/promociones-terminos-condiciones.component';
import { PromocionesComponent } from './promociones/promociones.component';
import {
  QuesonCategoriaRipleypuntosComponent,
} from './queson-categoria-ripleypuntos/queson-categoria-ripleypuntos.component';
import { QuesonRipleypuntosComponent } from './queson-ripleypuntos/queson-ripleypuntos.component';
import { TerminosLegalesComponent } from './terminos-legales/terminos-legales.component';
import {
  VitrinaSliderCanjepromocionesComponent,
} from './vitrina-slider-canjepromociones/vitrina-slider-canjepromociones.component';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [
    GenericModalComponent,

    FooterComponent,
    PantallaCargaComponent,
    PreguntasFrecuentesComponent,
    TerminosLegalesComponent,
    ClientPrimeCanjePromoComponent,
    CanjesPromocionesComponent,
    CanjesPromocionesLatamComponent,

    HeaderComponent,
    VitrinaSliderCanjepromocionesComponent,

    VitrinaSliderHomeComponent,
    BeneficiosComponent,
    InscripcionHomeComponent,
    PromocionesHomeComponent,
    VerPromocionesComponent,

    VitrinaComponent,
    CategoriasComponent,
    ClientPrimeComponent,
    BeneficiosCategoriasComponent,
    ModalAppTerminoscondicionesbeneficiosComponent,
    PreguntasFrecuentesCategoriasComponent,
    SegmentacionesComponent,
    BeneficiosSegmentacionComponent,
    ModalAppPuntoscalificablesComponent,

    QuesonRipleypuntosComponent,
    ComoacumularRipleypuntosComponent,
    ComoacumularPuntoscategoriaComponent,
    ComocanjearRipleypuntosComponent,
    QuesonCategoriaRipleypuntosComponent,
    ConsultarRipleypuntosComponent,

    AfiliacionBannerComponent,
    AfiliacionVitrinaComponent,
    AfiliacionSintarjetaComponent,
    AfiliacionTerminoslegalesComponent,
    AfiliacionPreguntasComponent,

    DescubreMillaslatamComponent,
    PantallaNotfoundComponent,
    PantallaNotcanjeComponent,
    PantallaNotripleyComponent,
    PaqueteLatamComponent,
    ConfirmaPaqueteComponent,
    ConfirmaEstadoCanjeComponent,
    PaqueteExitosoComponent,
    DetalleCanjeComponent,
    MiscanjesLatamComponent,

    PromocionesComponent,
    BeneficiosPromocionesComponent,
    ModalAppTerminoslegalComponent,

    PromocionesTerminosCondicionesComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    DigitOnlyModule,
    PipesModule,
    TooltipModule,
    FormsModule,
    DirectivesModule,
    NotificationsBellComponentModule,
    // NgxBarcodeModule,
    // QRCodeModule,
    NgxPaginationModule,
    NgbPaginationModule,
    SwiperModule,
  ],
  providers: [DecimalPipe],
  exports: [
    GenericModalComponent,

    FooterComponent,
    PantallaCargaComponent,
    PreguntasFrecuentesComponent,
    TerminosLegalesComponent,
    ClientPrimeCanjePromoComponent,
    CanjesPromocionesComponent,
    CanjesPromocionesLatamComponent,

    HeaderComponent,
    VitrinaSliderCanjepromocionesComponent,

    VitrinaSliderHomeComponent,
    BeneficiosComponent,
    InscripcionHomeComponent,
    PromocionesHomeComponent,
    VerPromocionesComponent,

    VitrinaComponent,
    CategoriasComponent,
    ClientPrimeComponent,
    BeneficiosCategoriasComponent,
    ModalAppTerminoscondicionesbeneficiosComponent,
    PreguntasFrecuentesCategoriasComponent,
    SegmentacionesComponent,
    BeneficiosSegmentacionComponent,
    ModalAppPuntoscalificablesComponent,

    QuesonRipleypuntosComponent,
    ComoacumularRipleypuntosComponent,
    ComoacumularPuntoscategoriaComponent,
    ComocanjearRipleypuntosComponent,
    QuesonCategoriaRipleypuntosComponent,
    ConsultarRipleypuntosComponent,

    AfiliacionBannerComponent,
    AfiliacionVitrinaComponent,
    AfiliacionSintarjetaComponent,
    AfiliacionTerminoslegalesComponent,
    AfiliacionPreguntasComponent,

    DescubreMillaslatamComponent,
    PantallaNotfoundComponent,
    PantallaNotcanjeComponent,
    PantallaNotripleyComponent,
    PaqueteLatamComponent,
    ConfirmaPaqueteComponent,
    ConfirmaEstadoCanjeComponent,
    PaqueteExitosoComponent,
    DetalleCanjeComponent,
    MiscanjesLatamComponent,

    PromocionesComponent,
    BeneficiosPromocionesComponent,
    ModalAppTerminoslegalComponent,

    PromocionesTerminosCondicionesComponent
  ],
  entryComponents: [
    GenericModalComponent,
  ]
})
export class ComponentsModule { }
