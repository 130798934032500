import { Component, HostListener, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Experiencia, Giftcard, Supercanjes } from '@common/models/canjeslista.model';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import { AuthService } from 'src/app/services/auth/auth.service';
import { CmsService } from 'src/app/services/cms/cms.service';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';

@Component({
  selector: 'app-canjes-promociones',
  templateUrl: './canjes-promociones.component.html',
  styleUrls: ['./canjes-promociones.component.scss']
})
export class CanjesPromocionesComponent implements OnInit {
  @ViewChild('swiper') swiper: SwiperComponent;
  config: SwiperOptions = {
    spaceBetween: 0,
    autoplay: false,
    loop: false,
    effect: 'slide',
    initialSlide: 0

  }
  mySlideOptionsPestanias = {
    autoplay: false,
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: false,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    delay: 10000,
    slideinterval: 10000,
  };

  rutaImgGC = '';
  rutaImgSVG = '';
  imgLogoWhite = '';
  rutaImgE = '';
  rutaImgS = '';
  imgLogoWhite_min = '';
  proXS = 'xs-';
  // ----giftcard
  //giftcardList: any = [];
  giftcardList: Giftcard[] = [
    {
      title: '',
      promoPrice: '',
      imgsrc: '',
      imgPromo: '',
      txtante: ''
    },
  ];
  // ----experiencias
  // ----vales digitales
  //espExperienciaList: any = [];
  espExperienciaList: Experiencia[] = [
    {
      imgBack: '',
      pricecurrent: '',
      priceante: '',
      titlebottom: '',
      titleLink: '',
      subtitlebottom: '',
      hrefLink: ''
    }
  ];
  // ---experiecias
  experienciaList: Experiencia[] = [
    {
      imgBack: '',
      pricecurrent: '',
      priceante: '',
      titlebottom: '',
      titleLink: '',
      subtitlebottom: '',
      hrefLink: ''
    }
  ];
  // ----super canjes
  // ----destacados
  // espSupercanjesList: any = [];
  espSupercanjesList: Supercanjes[] = [];
  // espSupercanjesList: Supercanjes[] = [
  //   {
  //     imgBack: '',
  //     pricecurrent: '',
  //     priceother: '',
  //     priceante: '',
  //     titlebottom: '',
  //     subtitlebottom: ''
  //   }
  // ];
  // ----super canjes
  // supercanjesList: any = [];
  supercanjesList: Supercanjes[] = [];
  // supercanjesList: Supercanjes[] = [
  //   {
  //     imgBack: '',
  //     pricecurrent: '',
  //     priceother: '',
  //     priceante: '',
  //     titlebottom: '',
  //     subtitlebottom: ''
  //   }
  // ];
  p1: any;
  p2: any;
  isActiveDefault = false;
  isGiftCard = false;
  isExperiencia = false;
  isSuperCanjes = false;
  isLatamPass = false;
  isAfiliacion = false;
  category = '';
  afiliacion = '';
  public currentPage = 1;
  public pageSize = 9;
  public currentPage1 = 1;
  public pageSize1 = 6;

  scrHeight = 0;
  scrWidth = 0;
  maxSizeCustom = 12;
  directionLinksCustom = true;
  verCondicionesResponsive = false;
  
  urlCataloPdfSuperCanjes = '';
  urlCataloImageSuperCanjes = '';
  nombreCatalogoSuperCanjes = '';
  activeBtnDownloadSuperCanjes = true;

  urlCataloPdfExperiencias = '';
  urlCataloImageExperiencias = '';
  nombreCatalogoExperiencias = '';
  activeBtnDownloadExperiencias = true;

  showLoaderSC = true;
  showLoaderExp = true;

  constructor(private route: ActivatedRoute,
    private elementRef: ElementRef,
    private authService: AuthService,
    private cmsService: CmsService, 
    private canjeMillasService: CanjeMillasService   
    ) {
    this.getScreenSize();
    this.getCatalogoPdfExperiencias();
    this.getCatalogoPdfSuperCanjes()
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth>767){
      this.verCondicionesResponsive = true;
    } else {
      this.verCondicionesResponsive = false;
    }    
    if (this.scrWidth <= 330) {
      this.maxSizeCustom = 3;
      this.directionLinksCustom = true;
    } else {
      if (this.scrWidth <= 670) {
        this.maxSizeCustom = 5;
        this.directionLinksCustom = true;
      } else {
        if (this.scrWidth <= 800) {
          this.maxSizeCustom = 8;
          this.directionLinksCustom = true;
        } else {
          this.maxSizeCustom = 20;
          this.directionLinksCustom = false;
        }
      }
    }
  }
  ngOnInit(): void {

    this.rutaImgGC = 'assets/img/canjes_promociones/';
    this.rutaImgSVG = 'assets/img/svg/';
    this.rutaImgE = 'assets/img/canjes_promociones/experiencias/';
    this.rutaImgS = 'assets/img/canjes_promociones/supercanjes/';
    this.imgLogoWhite = 'rp-white-logo-white.svg';
    this.imgLogoWhite_min = 'rp-white-logo-white-min.svg';
    this.getListaGiftcard();
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.category = params['category'];
        this.afiliacion = params['pag'];
        if (this.afiliacion === 'afiliacion') {
          this.isAfiliacion = true;
        } else {
          this.isAfiliacion = false;
        }
        if (this.category === 'giftcards') {
          this.isGiftCard = true;
          this.isExperiencia = false;
          this.isSuperCanjes = false;
          this.isLatamPass = false;
          this.isActiveDefault = false;
          this.swiper.swiperRef.slideTo(0);
          this.canjeMillasService.vistaPreFrecuentes = true;
        } else {
          if (this.category === 'experiencias') {
            this.isGiftCard = false;
            this.isExperiencia = true;
            this.isSuperCanjes = false;
            this.isLatamPass = false;
            this.isActiveDefault = false;
            this.getListaEspecialExperiencia();
            this.getListaEsperiencia();
            this.swiper.swiperRef.slideTo(0);
            this.canjeMillasService.vistaPreFrecuentes = true;
            if (params['banner']=='s'){
              this.ScrollIntoView("#dexperiencias");
              this.verCondicionesExp();
            }        
          } else {
            if (this.category === 'supercanjes') {
              this.isGiftCard = false;
              this.isExperiencia = false;
              this.isSuperCanjes = true;
              this.isLatamPass = false;
              this.isActiveDefault = false;
              this.getListaEspecialSuperCanje();
              this.getListaSuperCanje();
              this.swiper.swiperRef.slideTo(1);
              this.canjeMillasService.vistaPreFrecuentes = true;
              if (params['banner']=='s'){
                this.ScrollIntoView("#dsupercanj");
                this.verCondicionesExp();
              }        
  
            } else {
              if (this.category === 'latampass') {
                this.isGiftCard = false;
                this.isExperiencia = false;
                this.isSuperCanjes = false;
                this.isLatamPass = true;
                this.isActiveDefault = false;
                this.swiper.swiperRef.slideTo(1);
                this.canjeMillasService.vistaPreFrecuentes = false;
              } else {
                this.isGiftCard = false;
                this.isExperiencia = false;
                this.isSuperCanjes = false;
                this.isLatamPass = false;
                this.isActiveDefault = true;
                this.swiper.swiperRef.slideTo(0);
                this.canjeMillasService.vistaPreFrecuentes = true;
              }
            }
          }
        }

        if (params['banner']!='s'){
          this.ScrollIntoView("#CanjesTitle");
        }
      }
    );
  }

  async getCatalogoPdfExperiencias(){
    this.showLoaderExp = true;
    this.urlCataloPdfExperiencias ='assets/pdf/pdfVacio.pdf';
    this.nombreCatalogoExperiencias = 'Catálogo del mes';
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);
            this.cmsService.getCatalog('pdfExperiencias',token)
              .then((dataResult) => {
                if (dataResult.url.length > 0) {
                  this.urlCataloPdfExperiencias = dataResult.url;
                  this.urlCataloImageExperiencias = dataResult.urlImage;
                  this.nombreCatalogoExperiencias = dataResult.name; 
                  this.activeBtnDownloadExperiencias = dataResult.activeBtnDownload == false ? dataResult.activeBtnDownload : true;
                  this.showLoaderExp = false;
                  //console.log(dataResult);
                } else {
                  console.log('else error');                  
                }
              })
              .catch((error)=>{console.log(error)});
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log('error token');        
      }
    );
  }

  async getCatalogoPdfSuperCanjes(){
    this.showLoaderSC = true;
    this.urlCataloPdfSuperCanjes ='assets/pdf/pdfVacio.pdf';
    this.nombreCatalogoSuperCanjes = 'Catálogo del mes';
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);
            this.cmsService.getCatalog('pdfSupercanjes',token)
              .then((dataResult) => {
                if (dataResult.url.length > 0) {
                  this.urlCataloPdfSuperCanjes= dataResult.url;
                  this.urlCataloImageSuperCanjes = dataResult.urlImage;
                  this.nombreCatalogoSuperCanjes = dataResult.name;
                  this.activeBtnDownloadSuperCanjes = dataResult.activeBtnDownload == false ? dataResult.activeBtnDownload : true;
                  this.showLoaderSC = false;
                  // console.log(dataResult);
                } else {
                  console.log('else error');                  
                }
              })
              .catch((error)=>{console.log(error)});
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log('error token');        
      }
    );
  }
  getListaGiftcard() {
    const item_giftcardList = [
      {
        title: 'S/ 30',
        promoPrice: '3,000',
        imgsrc: 'assets/img/svg/i-RipleyPuntos-purple-logo.svg',
        imgPromo: 'gitCard30.png',
        txtante: ''
      },
      {
        title: 'S/ 100',
        promoPrice: '7,000',
        imgsrc: 'assets/img/svg/i-RipleyPuntos-purple-logo.svg',
        imgPromo: 'gitCard100.png',
        txtante: ''
      },
      {
        title: 'S/ 350',
        promoPrice: '20,000',
        imgsrc: 'assets/img/svg/i-RipleyPuntos-purple-logo.svg',
        imgPromo: 'gitCard350.png',
        txtante: ''
      },
      {
        title: 'S/ 1,100',
        promoPrice: '50,000',
        imgsrc: 'assets/img/svg/i-RipleyPuntos-purple-logo.svg',
        imgPromo: 'gitCard1100.png',
        txtante: ''
      },
      {
        title: 'S/ 2,500',
        promoPrice: '100,000',
        imgsrc: 'assets/img/svg/i-RipleyPuntos-purple-logo.svg',
        imgPromo: 'gitCard2500.png',
        txtante: ''
      }
    ];
    this.giftcardList = item_giftcardList;
  }

  //destacados
  getListaEspecialExperiencia() {
    const item_espExperienciaList = [
      {
        imgBack: '1_img_repsol.jpg',
        pricecurrent: '2,000',
        priceante: '',
        titlebottom: 'Repsol',
        subtitlebottom: 'Vale de S/ 30. Válido hasta el 31/01.',
        titleLink: 'Conoce más',
        hrefLink: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FEXPERIENCIAS%2FpdfLegalRepsol.pdf?alt=media&token=af0444d8-7758-4e31-a8df-f2186feb93dd'
      },
      {
        imgBack: '1_img_repsol.jpg',
        pricecurrent: '4,000',
        priceante: '',
        titlebottom: 'Repsol',
        subtitlebottom: 'Vale de S/ 50. Válido hasta el 31/01.',
        titleLink: 'Conoce más',
        hrefLink: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FEXPERIENCIAS%2FpdfLegalRepsol.pdf?alt=media&token=af0444d8-7758-4e31-a8df-f2186feb93dd'
      },
      {
        imgBack: '3_img_coneypark.jpg',
        pricecurrent: '3,000',
        priceante: '',
        titlebottom: 'Coney',
        subtitlebottom: 'CONEY PARK: Vale de S/50 + 40 Coney Bonos estándar. Válido hasta el 31/01.',
        titleLink: '',
        hrefLink: '',
      },
      // {
      //   imgBack: '3_img_PanchoCavero.jpg',
      //   pricecurrent: '500',
      //   priceante: '',
      //   titlebottom: 'Pancho Cavero',
      //   subtitlebottom: 'Vale de S/50. Válido hasta el 30/12.',
      //   titleLink: '',
      //   hrefLink: '',
      // },
      // {
      //   imgBack: '3_img_KFC.jpg',
      //   pricecurrent: '3,000',
      //   priceante: '',
      //   titlebottom: 'KFC',
      //   subtitlebottom: '6 Piezas de Pollo + 1 Papa familiar + 3 Ensaladas regulares + 3 Gaseosas regulares. Válido hasta el 31/08.',
      //   titleLink: '',
      //   hrefLink: '',
      // },
      // {
      //   imgBack: '3_img_montalvo.jpg',
      //   pricecurrent: '2,500',
      //   priceante: '',
      //   titlebottom: 'Montalvo',
      //   subtitlebottom: 'Vale de S/ 50.00. Válido hasta el 30/09.',
      //   titleLink: '',
      //   hrefLink: '',
      // },
    ];
    this.espExperienciaList = item_espExperienciaList;
  }

  //No destacados
  getListaEsperiencia() {

    /*
    const items_experiencias = [
      {
        imgBack: 'imgCinePlanet.png',
        pricecurrent: '1,200',
        priceante: '',
        titlebottom: '¡Nuevo! Vale de Combo Película',
        subtitlebottom: '1 pop corn grande + 2 bebidas medianas. Válido hasta el 31/07.',
        titleLink: '',
        hrefLink: ''
      }

    ];
    this.experienciaList = items_experiencias;

    */
  }

  //Destacados
  getListaEspecialSuperCanje() {
    const item_espSupercanjesList = [
      { imgBack: '32834669.jpg', pricecurrent: '3,500', priceother: '+ S/25', priceante: '', titlebottom: 'NIKE', subtitlebottom: 'SHORT ALIANZA LIMA NIKE PARA HOMBRE' },
      { imgBack: '26710482.jpg', pricecurrent: '3,000', priceother: '+ S/25', priceante: '', titlebottom: 'OSTER', subtitlebottom: 'ARROCERA 1.8 LTS CKSTRC1700R' },
      { imgBack: '33056411.jpg', pricecurrent: '3,500', priceother: '+ S/0', priceante: '', titlebottom: 'SKULLCANDY', subtitlebottom: 'Audifono Dime XT' },
    ];
/*
    const item_espSupercanjesList = [
      { imgBack: '30659292.jpg', pricecurrent: '4,500', priceother: '+ S/49.99', priceante: '', titlebottom: 'ADIDAS', subtitlebottom: 'ZAPATILLA-ADIDAS-HP9426-BREAKNET 2.0 BLANCO' },
      { imgBack: '30519544.jpg', pricecurrent: '2,500', priceother: '+ S/15', priceante: '', titlebottom: 'INDEX', subtitlebottom: 'JEANS PITILLO RASGADO BAS KATE NAC INX W23' },
      { imgBack: '32013474.jpg', pricecurrent: '2,500', priceother: '+ S/15', priceante: '', titlebottom: 'CLOUDBREAK', subtitlebottom: 'MOCHILA CLOUDBREAK BASE NEGRO' },
    ];
*/
    // this.espSupercanjesList = item_espSupercanjesList;
  }
  //No destacados
  getListaSuperCanje() {
    const items_supercanjes = [
      /*
      //JEAN
      { imgBack: '29528619.jpg', pricecurrent: '4,000', priceother: '+ S/80', priceante: '17,990 RPGo', titlebottom: 'SODA', subtitlebottom: 'JEAN SODA CHIARA\nSKU 29528619' },
      { imgBack: '29528322.jpg', pricecurrent: '4,000', priceother: '+ S/70', priceante: '16,990 RPGo', titlebottom: 'SODA', subtitlebottom: 'JEAN SODA BLAIR\nSKU 29528322' },
      { imgBack: '28706948.jpg', pricecurrent: '3,000', priceother: '+ S/40', priceante: '19,999 RPGo', titlebottom: 'INDEX', subtitlebottom: 'JEANS INDEX PARA MUJER ALGODÓN CERUP\nSKU 28706948' },

*/
    ];
    // this.supercanjesList = items_supercanjes;
  }


  activarTab(categoria: any) {
    if (categoria === 'giftcards') {
      this.isGiftCard = true;
      this.isExperiencia = false;
      this.isSuperCanjes = false;
      this.isLatamPass = false;
      this.isActiveDefault = false;
      this.canjeMillasService.vistaPreFrecuentes = true;
    } else {
      if (categoria === 'experiencias') {
        this.currentPage1 = 1;
        this.isGiftCard = false;
        this.isExperiencia = true;
        this.isSuperCanjes = false;
        this.isLatamPass = false;
        this.isActiveDefault = false;
        this.getListaEspecialExperiencia();
        this.getListaEsperiencia();
        this.canjeMillasService.vistaPreFrecuentes = true;
      } else {
        if (categoria === 'supercanjes') {
          this.currentPage = 1;
          this.isGiftCard = false;
          this.isExperiencia = false;
          this.isSuperCanjes = true;
          this.isLatamPass = false;
          this.isActiveDefault = false;
          this.getListaEspecialSuperCanje();
          this.getListaSuperCanje();
          this.canjeMillasService.vistaPreFrecuentes = true;
        } else {
          if (categoria === 'latampass') {
            this.isGiftCard = false;
            this.isExperiencia = false;
            this.isSuperCanjes = false;
            this.isLatamPass = true;
            this.isActiveDefault = false;
            this.canjeMillasService.vistaPreFrecuentes = false;
          } else {
            this.isGiftCard = false;
            this.isExperiencia = false;
            this.isSuperCanjes = false;
            this.isLatamPass = false;
            this.isActiveDefault = true;
            this.canjeMillasService.vistaPreFrecuentes = true;
          }
        }
      }
    }

    this.ScrollIntoView("#CanjesTitle");

  }
  slidePrevPestania() {

    const index = this.swiper.swiperRef.activeIndex;
    if (index === 0) {
      this.swiper.swiperRef.slideTo(1);
    } else {
      this.swiper.swiperRef.slidePrev();
    }
  }
  slideNextPestania() {
    const index = this.swiper.swiperRef.activeIndex;
    if (index === 1) {
      this.swiper.swiperRef.slideTo(0);
    } else {
      this.swiper.swiperRef.slideNext()
    }

  }

  onPageChange(currentPag: string) {
    // dsupercanj
    this.ScrollIntoView(currentPag);
  }


  ScrollIntoView(elem: string) {
    setTimeout(() => {
      document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }, 300);

  }
  verCondicionesGC(){
    const section = this.elementRef.nativeElement.querySelector('#giftCarVerCondiciones')
    section?.scrollIntoView()    
    if (section?.getAttribute('aria-expanded') === 'false') {
      section?.click();
      section?.setAttribute('aria-expanded', 'true');
    }    
  }
  verCondicionesExp(){
    const section = this.elementRef.nativeElement.querySelector('#experienciasVerCondiciones')
    section?.scrollIntoView()    
    if (section?.getAttribute('aria-expanded') === 'false') {
      section?.click();
      section?.setAttribute('aria-expanded', 'true');
    }    
  }  

}
