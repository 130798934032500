import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CanjeMillasService } from 'src/app/services/canjes/canje-millas.service';
import { ICustomerResponse } from 'src/app/common/interfaces/customer.interface';
import { DeviceService } from '@services/device/device.service';

@Component({
  selector: 'app-descubre-millaslatam',
  templateUrl: './descubre-millaslatam.component.html',
  styleUrls: ['./descubre-millaslatam.component.scss'],
})
export class DescubreMillaslatamComponent implements OnInit {

  typeDoc = '1';
  isValid = true;
  mostrarPantallaCarga = false;

  min_length_nroDocumento: number;
  max_length_nroDocumento: number;

  typeFlow = 'canje';
  textTitle: string = '¡Descubre todos los paquetes <br>de Millas LATAM Pass que tenemos para ti!';
  textSubTitle: string = 'Ingresa tu documento de identidad para empezar a canjear';

  identidadForm: FormGroup;

  public pathSinRegistroBancoRipley = 'not-ripley';
  public pathSinPuntosMinimos = 'not-points';
  public pathSinMiembroLatam = 'not-latam';
  public pathSeleccionPaquete = 'canje-paquete-latam';
  public pathSeleccionConfirmaEstadoCanje = 'confirma-estado-canje';
  public pathSeleccionMisCanjes = 'mis-canjes-latam';
  showLoader = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.createForm();

    this.route.queryParams.subscribe(
      params => {
        this.typeFlow = params['type'] ? params['type'] : 'canje';
        this.typeFlow = this.typeFlow == 'miscanjes' ? this.typeFlow : 'canje';
        if (this.typeFlow == 'miscanjes') {
          this.textTitle = '¡Revisa aquí el estado de tus canjes!';
          this.textSubTitle = 'Ingresa tu documento de identidad para acceder a tus canjes';
        }
        console.log('typeFlow: ', this.typeFlow);
      }
    );
  }

  createForm() {
    this.identidadForm = this.formBuilder.group({
      nroDocumentoPrestamo: [{ value: null, disabled: false }, [Validators.required]],
    });

    this.max_length_nroDocumento = 8;

  }

  get control() { return this.identidadForm.controls; }

  selectedTypeDoc(type) {
    this.typeDoc = type;
    this.isValid = true;

    // this.identidadForm.get('nroDocumentoPrestamo').clearValidators();
    this.identidadForm.get('nroDocumentoPrestamo').setValue('');
    if (type === '01') {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(8)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 8;
    } else {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(11)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 11;
    }

    // let nroDocumento = this.identidadForm.get('nroDocumentoPrestamo').value;
    // console.log(nroDocumento);
  }

  onChange() {
    if (this.isValid === false) {
      this.nroDocumentoValida();
    }
  }

  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  nroDocumentoValida() {
    this.isValid = true;
    const nroDocumento = this.identidadForm.get('nroDocumentoPrestamo').value;

    if (!(nroDocumento && nroDocumento !== '')) {
      this.isValid = false;
    } else {
      if (this.typeDoc === '1') {
        if (nroDocumento.length !== 8) {
          this.isValid = false;
        }
      } else {
        if (nroDocumento.length < 9 || nroDocumento.length > 11) {
          this.isValid = false;
        }
      }
    }
    return this.isValid;
  }

  onConsultar() {
    const nroDocumentoValidate = this.nroDocumentoValida();
    if (!nroDocumentoValidate) {
      // marcacionErrorNroDocumento();
    }
    if (nroDocumentoValidate) {
      //console.log('grecaptcha;');

      // let RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;
      const token = '';
      this.consultarPointsClient(token);
    }
  }

  async consultarPointsClient(token) {
    this.showLoader = true;
    const document = this.identidadForm.get('nroDocumentoPrestamo').value;
    const client = {
      documentType: this.typeDoc,
      documentNumber: document,
      minimumPoints: 900,
      captcha: token,
      monitorInput: {
        url : window.location.host,
        device : this.deviceService.getWildField1(),
        firmware : this.deviceService.getPlatformName(),
        docName : window.location.pathname,
        mobileNumber : '',
        ip : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion(),
        deviceId: this.deviceService.getDeviceId(),
        deviceName: this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice(),
        imei: this.deviceService.getIMEI(),
        country: await this.deviceService.getCountry(),
        city: await this.deviceService.getCity(),
        isp: await this.deviceService.getISP(),
        navegadorVersion: this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion(),
        language: navigator.language,
        architecture: this.deviceService.getArchitecture()
      }
    };

    localStorage.setItem('objDataValidationCustomer', '');
    this.pantallaDeCarga(true);

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        //console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            // this.tokenService.setToken(bodyResponse.access_token);
            // this.localStorageService.set(AppConstants.Session.TOKEN_TYPE, bodyResponse.token_type);
            // this.localStorageService.set(AppConstants.Session.EXPIRES_IN, bodyResponse.expires_in);
            // this.localStorageService.set(AppConstants.Session.SCOPE, bodyResponse.scope);

            const token = bodyResponse.access_token;
            //console.log(token);

            this.canjeMillasService.validationCustomer(client, token)
              .then((dataResult) => {
                this.showLoader = false;
                // console.log('validationCustomer');
                // console.log(dataResult);

                if (dataResult.code === 0) {
                  localStorage.setItem('objDataValidationCustomer', JSON.stringify(dataResult));

                  // // this.document.location.href = '/' + this.pathSeleccionPaquete;
                  // this.router.navigateByUrl(this.pathSeleccionPaquete, { state: { data: dataResult } });

                  if (this.typeFlow == 'canje') {
                    this.router.navigateByUrl(this.pathSeleccionPaquete, { state: { data: dataResult } });
                  }
                  else {
                    this.router.navigateByUrl(this.pathSeleccionConfirmaEstadoCanje, { state: { data: dataResult } });
                    // this.router.navigateByUrl(this.pathSeleccionMisCanjes, { state: { data: dataResult } });
                  }

                } else {
                  if (dataResult.code === 1) {
                    this.document.location.href = '/' + this.pathSinRegistroBancoRipley;
                  }
                  if (dataResult.code === 2) {
                    this.document.location.href = '/' + this.pathSinPuntosMinimos;
                  }
                  if (dataResult.code === 3) {
                    this.document.location.href = '/' + this.pathSinMiembroLatam;
                  } else {
                    // this.document.location.href = '/' + this.pathSinRegistroBancoRipley;
                  }
                  // this.router.navigateByUrl('segmentacion');
                }
                this.pantallaDeCarga(false);
              })
              .catch(() => {
                console.log('catch');
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
      }
    );

  }
  pantallaDeCarga(booleano) {
    this.mostrarPantallaCarga = booleano;
    if (booleano) {
      // $(".app-opacity").show();
    } else {
      // $(".app-opacity").hide();+
    }
  }


}
